const listFilter = document.querySelectorAll('.item-filter')
const $projects = document.querySelectorAll('.item-gallery')
const $projectsWrapper = document.querySelector('.project-gallery')

listFilter.forEach(element => {
  element.addEventListener('click', () => {
    $projectsWrapper.classList.add('-filtering')
    setTimeout(() => {
      $projects.forEach(project => {
        if (element.id === '0') {
          project.style.display = 'block'
        } else {
          project.style.display = element.id === project.id ? 'block' : 'none'
        }
      })
      $projectsWrapper.classList.remove('-filtering')
    }, 400)

    listFilter.forEach(filter => {
      filter.classList.remove('-active')
    })

    element.classList.add('-active')
  })
})
