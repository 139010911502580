/**
 * Add attributes and classes for houdini elements
 * @param {HTMLElement} nav <ul> generated by WordPress with all sub categories links
 */
export default function initSubCategoryNav(nav) {
  var cont = 1
  nav.querySelectorAll('.children').forEach(item => {
    const link = item.previousElementSibling
    link.setAttribute('data-houdini-toggle', 'sub-category-' + cont)
    item.setAttribute('id', 'sub-category-' + cont)
    item.setAttribute('data-houdini', '')
    item.setAttribute('data-houdini-animated', '')
    item.setAttribute('class', 'submenu')
    cont++
  })
}
