import Flickity from 'flickity-imagesloaded'
import 'flickity-fade'
import 'flickity-as-nav-for/as-nav-for'

// Flickity - equal cell height hack
// https://codepen.io/desandro/pen/ZXEGVq
Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize')
}

Flickity.createMethods.push('_createResizeClass')

var resize = Flickity.prototype.resize
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize')
  resize.call(this)
  this.element.classList.add('flickity-resize')
}

// Initialize all Flickity instances with a common config
// This will match any elements with a data-slider attribute
const sliders = document.querySelectorAll('[data-slider]')
sliders.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: true,
      contain: true,
      groupCells: true,
      imagesLoaded: true,
      arrowShape: {
        x0: 25,
        x1: 55,
        y1: 50,
        x2: 60,
        y2: 50,
        x3: 30
      }
    })
)

// Initialize all Flickity instances with a common config
// Initialize all infinite sliders
const autoPlaySliders = document.querySelectorAll('[data-slider-loop]')
autoPlaySliders.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: true,
      contain: true,
      groupCells: 1,
      cellAlign: 'left',
      wrapAround: true,
      imagesLoaded: true,
      arrowShape: {
        x0: 25,
        x1: 55,
        y1: 50,
        x2: 60,
        y2: 50,
        x3: 30
      }
    })
)

// Initialize all Flickity instances with a common config
// Initialize
const slidersMain = document.querySelectorAll('[data-slider-controller]')
slidersMain.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: true,
      contain: true,
      wrapAround: true,
      groupCells: true,
      fade: true,
      imagesLoaded: true,
      arrowShape: {
        x0: 25,
        x1: 55,
        y1: 50,
        x2: 60,
        y2: 50,
        x3: 30
      }
    })
)

const sliderNav = document.querySelectorAll('[data-slider-manipulated]')
sliderNav.forEach(
  slider =>
    new Flickity(slider, {
      asNavFor: slidersMain[0],
      pageDots: false,
      draggable: false,
      setGallerySize: false,
      groupCells: false,
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false
    })
)

// Initialize all Flickity instances with a common config
// Initialize all full-screen sliders
const fullSliders = document.querySelectorAll('[data-slider-full]')
fullSliders.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: true,
      groupCells: false,
      cellAlign: 'left',
      autoPlay: true,
      contain: true,
      prevNextButtons: false
    })
)

// Fire the resize event when all DOM content loads
// Required because some fonts can take a while to load and cause content shift
window.addEventListener('load', () => {
  const sliders = Array.from(document.querySelectorAll('[data-slider]'))
  sliders.forEach(slider => {
    var flkty = Flickity.data(slider)
    flkty.resize()
  })

  const slidersManipulated = Array.from(
    document.querySelectorAll('[data-slider-manipulated]')
  )
  slidersManipulated.forEach(slider => {
    var flkty = Flickity.data(slider)
    flkty.resize()
  })

  const slidersController = Array.from(
    document.querySelectorAll('[data-slider-controller]')
  )
  slidersController.forEach(slider => {
    var flkty = Flickity.data(slider)
    flkty.resize()
  })
})

export default Flickity
