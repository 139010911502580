/* global raindrop_localize */

const loadMoreButton = document.querySelector('#load-more-button')
const postContainer = document.querySelector('#posts-container')

if (loadMoreButton) {
  loadMoreButton.addEventListener('click', () => {
    loadMoreButton.disabled = true

    let formData = new FormData()
    formData.append('action', 'load_posts_by_ajax')
    formData.append('security', raindrop_localize.security)
    formData.append('query', raindrop_localize.posts)
    formData.append('page', raindrop_localize.current_page)

    let request = new XMLHttpRequest()
    request.open('POST', raindrop_localize.ajaxurl)
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        raindrop_localize.current_page++
        // Success!
        if (!request.responseText) {
          loadMoreButton.textContent = 'All posts loaded'
          return
        }
        postContainer.insertAdjacentHTML('beforeend', request.responseText)
        loadMoreButton.disabled = false
      } else {
        // We reached our target server, but it returned an error
        console.log(request)
      }
    }

    request.onerror = function() {
      // There was a connection error of some sort
      console.log(request)
    }

    request.send(formData)
  })
}
