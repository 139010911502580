/**
 * Animate max-height on elements with [data-houdini-animated]
 * See houdini-animated mixin in SCSS
 * @param {CustomEvent} event Custom event created by Houdini
 */
function updateMaxHeight(event) {
  const { content } = event.detail
  if (!content.matches('[data-houdini-animated]')) return // No animation required

  if (event.type === 'houdiniExpand') {
    content.style.maxHeight = content.scrollHeight + 'px'
  } else {
    content.style.maxHeight = null
  }

  // Traverse all parent Houdini elements and adjust their height accordingly
  // This is required when we have nested collapsibles
  let el = content
  while (el.parentNode !== document.documentElement) {
    el = el.parentNode
    if (!el.matches('[data-houdini-animated]')) continue // No animation required

    const currentHeight = parseInt(el.style.maxHeight)
    if (isNaN(currentHeight)) continue // No need to adjust max-height if not defined

    const sign = event.type === 'houdiniExpand' ? 1 : -1
    el.style.maxHeight = currentHeight + content.scrollHeight * sign + 'px'
  }
}

/**
 * Add animated max-height to elements with [data-houdini-animated]
 */
export default function initHoudiniAnimations() {
  document.addEventListener('houdiniExpand', updateMaxHeight)
  document.addEventListener('houdiniCollapse', updateMaxHeight)
}
