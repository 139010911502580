import Houdini from 'houdinijs'
import '../scss/index.scss'
import './polyfills'
import initDateNav from './date-nav'
import initSubCategoryNav from './sub-category-nav'
import Flickity from './flickity'
import ListFilter from './filter-nav'
import LoadMoreButton from './ajax-load-more-button'
import initHoudiniAnimations from './houdiniAnimations'

const activeClass = '-active'
const root = document.documentElement
const siteNavDropdowns = document.querySelectorAll('.site-nav-dropdown')
const dateNav = document.querySelector('#sidebar-date')
const subCategoryNav = document.querySelector('#sidebar-category')
const activeAccordions = document.querySelectorAll(
  `[data-houdini-group].${activeClass}`
)

// Update <html> class to indicate JS is enabled
root.classList.remove('no-js')
root.classList.add('js')

// Initialize houdini animations on msx-height
initHoudiniAnimations()

// Initialize all collapsibles with Houdini
const houdiniInstance = new Houdini('[data-houdini]', {
  expandedClass: activeClass
})

// Initialize all collapsibles with Houdini
const houdiniAccordion = new Houdini('[data-houdini-group]', {
  expandedClass: activeClass,
  isAccordion: true,
  icon: false,
  collapseOthers: true
})

// Adjust the parent element height when a dropdown is toggled
// Required because the dropdown is absolutely positioned to allow overflow
siteNavDropdowns.forEach(dropdown => {
  const parent = dropdown.parentNode
  const button = dropdown.previousElementSibling

  dropdown.addEventListener('houdiniExpand', () => {
    siteNavDropdowns.forEach(otherDropdown => {
      if (otherDropdown !== dropdown) houdiniInstance.collapse(otherDropdown)
    })
    parent.style.height = button.scrollHeight + dropdown.scrollHeight + 'px'
  })

  dropdown.addEventListener('houdiniCollapse', () => {
    parent.style.height = button.scrollHeight + 'px'
  })
})

// Expand Active Accordions On load
activeAccordions.forEach(content => houdiniAccordion.expand(content))

// Group archives by date
if (dateNav) initDateNav(dateNav)

// Group archives by categories
if (subCategoryNav) initSubCategoryNav(subCategoryNav)

// Expose to the window for use in templates
window.Flickity = Flickity
